<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs3 text-left>
            <span class="mainHeader">Menu Master Table</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
          class="pa-6"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap>
            <v-flex xs12 pb-2><span class="mainHeader2">Add Main Menu</span></v-flex>
            <v-flex xs3 align-self-end>
              <v-text-field
                label="Name"
                outlined
                v-model="mainName"
                type="text"
                hide-details
                dense
              ></v-text-field>
            </v-flex>
            <v-flex xs3 pl-2 align-self-end>
              <v-text-field
                label="Order"
                outlined
                v-model="mainOrder"
                type="number"
                hide-details
                dense
              ></v-text-field>
             
            </v-flex>
             <v-flex xs3 pl-4>
                <v-text-field
                  label="Route"
                  outlined
                  v-model="mainRoute"
                  type="text"
                  hide-details
                  dense
                ></v-text-field
              ></v-flex>
            <v-flex align-self-center xs3 pl-2 align-self-end>
              <v-btn dark color="#3F053C" @click="addMain()"> Save </v-btn>
            </v-flex>
            <v-flex xs12 py-4>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12><span class="mainHeader2">List</span></v-flex>
          </v-layout> 
          <v-card outlined class="pa-4 my-4" v-for="(value, i) in MenuList" :key="i">
          <v-layout wrap  pb-4>
            <v-flex xs12>
              <v-layout wrap pb-2>
               <v-flex xs2 align-self-center>
                MainMenu {{i+1}} : 
               </v-flex>
                 <v-flex xs3>
                  <v-text-field
                label="Order"
               
                v-model="value.order"
                type="text"
                hide-details
                dense
              ></v-text-field>
              </v-flex>
            <v-flex xs2 pl-2>
               <v-text-field
                label="Name"
                
                v-model="value.name"
                type="text"
                hide-details
                dense
              ></v-text-field>
            </v-flex>
            <v-flex xs2 pl-2>
               <v-text-field
                label="Route"
                v-model="value.route"
                type="text"
                hide-details
                dense
              ></v-text-field>
            </v-flex>
            <v-flex xs2 pl-2 align-self-center >
              <v-btn small dark color="#3F053C" @click="editMain(value)">
                Edit Main
              </v-btn>
                <v-icon color="#3F053C" @click="deleteMain(value._id)">
                mdi-delete-outline
              </v-icon>
            </v-flex>
              </v-layout>
              <v-layout wrap  pb-4>
            <v-flex xs2 align-self-center>Add Submenu</v-flex>
           
            <v-flex xs3>
              <v-text-field
                label="Name"
                solo
                v-model="subName"
                type="text"
                hide-details
                dense
              ></v-text-field>
            </v-flex>
            <v-flex xs2 pl-4>
              <v-text-field
                label="Order"
                solo
                v-model="subOrder"
                type="number"
                hide-details
                dense
              ></v-text-field
            ></v-flex>
            <v-flex xs2 pl-4>
              <v-text-field
                label="Route"
                solo
                v-model="subRoute"
                type="text"
                hide-details
                dense
              ></v-text-field
            ></v-flex>
            <v-flex xs2 pl-4 align-self-center class="buttons1">
              <v-btn class="buttons1" small dark color="#3F053C" @click="addSub(value._id)">
                Save
              </v-btn>
            </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs9 py-4>
              <v-divider></v-divider>
            </v-flex>
            <template v-if="SubMenuList">
            <v-flex xs12 class="subhed2" v-if="SubMenuList.length>0">
              SubMenu List
            </v-flex>
            <v-flex xs10 style="background-color:#fcd2fc" pa-2 v-if="SubMenuList.length>0">
          <v-layout wrap  pb-4>
            <v-flex xs2>Order</v-flex>
            <v-flex xs4 pl-1>Sub Name</v-flex>
            <v-flex xs4 pl-1>Route</v-flex>
            <v-flex xs2 pl-1>Action</v-flex>
          </v-layout>
          <template v-for="(item, i) in SubMenuList">
            <v-layout wrap :key="i"  pb-2 v-if="value.name==item.menuId.name">
              <v-flex xs2>
                  <v-text-field
                label="SubOrder"
                outlined
                v-model="item.order"
                type="text"
                hide-details
                dense
              ></v-text-field>
              </v-flex>
            <v-flex xs4 pl-1>
               <v-text-field
                label="SubName"
                outlined
                v-model="item.name"
                type="text"
                hide-details
                dense
              ></v-text-field>
            </v-flex>
            <v-flex xs4 pl-1>
               <v-text-field
                label="SubRoute"
                outlined
                v-model="item.route"
                type="text"
                hide-details
                dense
              ></v-text-field>
            </v-flex>
            <v-flex xs2 pl-1 align-self-center class="buttons1">
              <v-btn class="buttons1" small dark color="#3F053C" @click="editSub(item)">
                Edit
              </v-btn>
              <v-icon color="#3F053C" @click="deleteSub(item._id)">
                mdi-delete-outline
              </v-icon>
            </v-flex>
            </v-layout>
            </template>
       </v-flex>
            </template>
          </v-layout>
          </v-card>
         
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      mainName: null,
      mainOrder: null,
      mainRoute:null,
      subName:null,
      subOrder:null,
      subRoute:null,
      MenuList: [],
      SubMenuList: [],
    };
  },
  mounted() {
    this.getMenu();
    this.getSubMenu();
  },
  methods: {
    addMain() {
      axios({
        url: "/admin/menu/add",
        method: "POST",
        data: {
          name: this.mainName,
          order: this.mainOrder,
          route: this.mainRoute,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.mainName = null;
            this.mainOrder = null;
            this.mainRoute = "";
            this.getMenu();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addSub(item) {
      axios({
        url: "/admin/submenu/add",
        method: "POST",
        data: {
          menuId: item,
          name: this.subName,
          order: this.subOrder,
          route: this.subRoute,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.mainName = null;
            this.mainOrder = null;
            this.getSubMenu();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getMenu() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/admin/menu/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.MenuList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getSubMenu() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/admin/submenu/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.SubMenuList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
     deleteMain(item1) {
      axios({
        url: "/admin/menu/delete/" + item1,
        method: "POST",
        headers: {
            "x-auth-token": localStorage.getItem("token"),
        },
        // data: {
        //   id: item1,
        // },
      })
        .then((response) => {
          this.delete = false;
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getMenu();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     deleteSub(item2) {
      axios({
        url: "/admin/submenu/delete/" + item2,
        method: "POST",
        headers: {
            "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getSubMenu();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
       editMain(item3) {
        console.log("name2=",item3.name)
      axios({
        url: "/admin/menu/edit",
        method: "POST",
        headers: {
         "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id:item3._id,
          name: item3.name,
          order: item3.order,
          route: item3.route,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg =response.data.msg;
            this.showsnackbar = true;
            this.getMenu();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
       editSub(item4) {
      axios({
        url: "/admin/submenu/edit",
        method: "POST",
        headers: {
            "x-auth-token": localStorage.getItem("token"),
        },
        data: {
         id:item4._id,
          name: item4.name,
          order: item4.order,
          route: item4.route,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getSubMenu();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
